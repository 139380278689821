import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from './routes';


import CommonPassLogin from './modules/commonpass/components/Login';
import ClearPassLogin from './modules/clearpass/components/Login';
import Authorize from './modules/commonpass/components/Authorize';
import ClearPassAuthorize from './modules/clearpass/components/Authorize';


function App() {
  return (
    <Switch>
      <Route path={routes.schedule} exact>
        <Redirect to={routes.commonpass.default} />
      </Route>
      <Route path={routes.commonpass.default}>
        <CommonPassLogin />
      </Route>
      <Route path={routes.commonpass.authorize}>
        <Authorize />
      </Route>
      <Route path={routes.clearpass.default}>
        <ClearPassLogin />
      </Route>
      <Route path={routes.clearpass.authorize}>
        <ClearPassAuthorize />
      </Route>
    </Switch>
  );
}

export default App;
