import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import Input from '../../shared/components/form/Input';
import './Styles.css';
import FormLabel from '../../shared/components/form/FormLabel';
import Header from '../Layout/Header';
import axios from 'axios';
import _ from 'lodash';
export enum Breakpoints {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1500,
}

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}${alpha !== undefined ? `, ${alpha}` : ''})`;
}

interface InputElementProps {
  hasError: boolean;
  size?: any;
}


interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  noPadding?: boolean;
}
const Error = styled.div`
  color: red;
  margin-top: 15px;
  text-align: center;
`;
const css = `
.input{
  border-radius:none;
}
`;

const MainHeader = styled.div`
  text-align: center;
  font-weight: bold;
  @media (min-width: 1440px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
  @media (min-width: 600px) and (max-width: 1023px) {
    font-size: 2.5rem;
  }
  @media (min-width: 360px) and (max-width: 480px) {
    font-size: 1.8rem;
  }
  @media (max-width: 320px) {
    font-size: 1.5rem;
  }
  color: #505560;

  margin-bottom: 1rem;
`;

const MainContent = styled.div`

margin: 0 auto;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '540px';
  }};
  // padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
  @media (min-width: 320px) and (max-width: 480px) {
    box-shadow:none;
  }
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
border-bottom-right-radius: 0.5rem;
border-bottom-left-radius: 0.5rem;
height:100%;
max-height:100%;

}
`;
const SubContent = styled.div`
  @media (min-width: 1024px) {
    padding: 2rem 3.2rem 3.2rem 3.2rem;
    border: 0.2px solid #e8e7c6;
  }
  @media (min-width: 600px) and (max-width: 1023px) {
    padding: 2.5rem;
    border: 0.2px solid #e8e7c6;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 1.3rem;
    border: none;
  }
`;

export const CommonPassContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '540px';
  }};
  // padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
  @media (min-width: 320px) and (max-width: 480px) {
    box-shadow:none;
  }
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
border-radius: 0.5rem;
height:100%;
`;

type Login = {
  username: string;
  password: string;
};

const ClearPassLogin: React.FC = (props: any) => {
  let data = window.location.search.split('?');
  let queryParams = window.location.search;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [throwErrorMessage, setthrowErrorMessage] = useState('');
  let throwError: any;
  let isInvalid: boolean = false;
  const authenticateUser = async (username: string, password: string) => {
    isInvalid = false;
    setIsRequesting(true);
    setLoading(true)
    axios({
      method: 'post',
      url: 'https://us-central1-worksite-labs-ccb7d.cloudfunctions.net/ClearPasslogin',
      data: {
        data: {
          emailid: username,
          phone: password
        }
      }
    })
      .then(function (response) {
        if (response.status == 200) {
          setIsRequesting(false);
          let sysData = response.data.result.appointments;
          if (sysData.length > 0) {
            localStorage.setItem('clearpassusername', btoa(sysData[0]['email']));
            if (sysData[0]['location']['limsinfo'] !== undefined && sysData[0]['location']['limsinfo'] !== null && sysData[0]['location']['limsinfo']['type'] == 'crelio') {
              localStorage.setItem('clearpasstype', btoa('CR'));
            }
            else {
              localStorage.setItem('clearpasstype', btoa('QB'));
            }
            setLoading(false)
            window.location.href = '/clear-pass/authorize' + queryParams;
          }
          else {
            isInvalid = true;
            setLoading(false)
            setthrowErrorMessage('Invalid username or password');
          }
        }

      });

  };

  return (
    <div>
      <Header></Header>

      <MainContent>
        <SubContent>
          <MainHeader>Log into your account</MainHeader>

          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={async ({ username, password }: { username: string; password: string }) => {
              setLoading(true);

              console.log('username, password', username, password);

              try {
                await authenticateUser(username, password);
              } catch (e) {
                setError('Incorrect username or password.');
                setLoading(false);
              }
            }}
            enableReinitialize={false}
          >
            {() => (
              <Form>
                <FormLabel label="Username">
                  <Input name="username" isRequired placeholder="Enter your email as username" />
                </FormLabel>
                <FormLabel label="Password">
                  <Input name="password" type="password" isRequired placeholder="Enter your mobile number as password" />
                </FormLabel>
                <div className="Error">{throwErrorMessage}</div>
                <button className="Button" type="submit" style={{ backgroundColor: '#2563EA' }}>
                  {loading ? 'Processing...' : 'Log in'}
                </button>
                {error && <Error>{error}</Error>}
              </Form>
            )}
          </Formik>

          {/* </Formik> */}
        </SubContent>
      </MainContent>
    </div>
  );
};

export default ClearPassLogin;
