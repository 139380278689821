import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import axios from 'axios';
import _ from 'lodash';
import Header from '../Layout/Header';
import './Styles.css'


interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  noPadding?: boolean;
}

const MainContent = styled.div`

margin: 0 auto;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '540px';
  }};
  // padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
  @media (min-width: 320px) and (max-width: 480px) {
    box-shadow:none;
  }
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
border-radius: 0.5rem;
height:100%;
max-height:100%;

}
`;
const SubContent = styled.div`
  @media (min-width: 1024px) {
    padding: 3.2rem;
    border: 0.2px solid #e8e7c6;
  }
  @media (min-width: 600px) and (max-width: 1023px) {
    padding: 3.2rem;
    border: 0.2px solid #e8e7c6;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 1.3rem;
    border: none;
  }
`;
let profilesLists: any = [];
let authtoken: any;
let username: any;
let type: any;
const Authorize: React.FC = (props: any) => {
  username = localStorage.getItem('username');
  username = atob(username);
  type = localStorage.getItem('type');
  type = atob(type);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [dropvalue, setdropvalue] = useState(null);
  const [throwErrorMessage, setthrowErrorMessage] = useState('');
  useEffect(() => {
    getProfiles();
  }, []);

  function getProfiles() {
    
    setLoading(true)
    axios({
      method: 'post',
      url: 'https://us-central1-worksite-labs-ccb7d.cloudfunctions.net/getcommonpassdetails',
      data: {
        data: {
          confirmationId: username
        }
      }
    })
      .then((response) => {
        response.data.result.appointments.forEach(async function (appointment: any, index: any) {
          var obj = {
            value: appointment.value,
            label: appointment.label,
          };
          profilesLists.push(obj);
        });

        
    setLoading(false)

      })

  }
  function getToken() {
    if (dropvalue === null) {
      setthrowErrorMessage('Please select a profile to continue');
    } else {
      setLoading(true)
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let client_id = params.get('client_id');
      let redirect_uri = params.get('redirect_uri');
      let launch = params.get('launch');
      let scope = params.get('scope');
      let state = params.get('state');
      let aud = params.get('aud');
      axios({
        method: 'post',
        url: 'https://us-central1-worksite-labs-ccb7d.cloudfunctions.net/getcode',
        data: {
          data: {
            response_type: "code",
            client_id: client_id,
            redirect_uri: redirect_uri,
            launch: launch,
            scope: scope,
            state: state,
            aud: aud,
            profileID: JSON.parse(JSON.stringify(dropvalue)).value,
            type: type
          }
        }
      })
        .then((response) => {
          let value = response.data.result.data;
          localStorage.setItem('authoricecode', btoa(value));

          let redirect_uri = value.split('?')[0];
          let code = value
            .split('?')[1]
            .split('&')[0]
            .split('=')[1];

          let username = localStorage.getItem('username');
          if (username != null) {
            username = atob(username);
          }
          setLoading(false)
          window.location.href = value;

        });
    }
  }

  function dropvaluechange(e: any) {
    setdropvalue(e);
    setthrowErrorMessage('');
  }
  return (
    <div>
      <Header></Header>

      <MainContent>
        <SubContent>
          <h1>
            <b>Authorize</b>
          </h1>
          <h1>
            <b>"CommonPass"</b>
          </h1>
          {/* </Header> */}
          <p style={{ margin: '0.5rem' }}>By selecting a profile below you agree to authorize the app with the following permissions:</p>
          <li style={{ margin: '0.5rem' }}>Read your COVID-19 lab test results or vaccination history</li>
          <li style={{ margin: '0.5rem' }}>Read your name</li>
          <p style={{ margin: '0.5rem' }}>Whose test and vaccination information are you looking for?</p>
          <Select value={dropvalue} onChange={(e: any) => dropvaluechange(e)} options={profilesLists} />
          <div className="Error">{throwErrorMessage}</div>

          <button className="Button" type="button" onClick={getToken} style={{ backgroundColor: '#2563EA', marginBottom: '2rem', marginTop: '2rem' }}>
          {loading ? 'Processing...' : 'Go'}
          </button>
        </SubContent>
      </MainContent>
    </div>
  );
};

export default Authorize;

function componentDidMount() {
  throw new Error('Function not implemented.');
}
