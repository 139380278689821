import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../../styles/colors';

// import  Authorize  from '../commonpass/components/Authorize';

import AppLogo from '../../../assets/reactlogo.png';
import {  Image } from 'semantic-ui-react';
export enum Breakpoints {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1500,
}

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;


interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  noPadding?: boolean;
}
export const CommonPassContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '540px';
  }};
  // padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
  @media (min-width: 320px) and (max-width: 480px) {
    box-shadow:none;
  }
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
border-radius: 0.5rem;
height:100%;
`;
// box-shadow: inset 0px 2px 1px rgb(0 0 0 / 30%);

const LogoContainer = styled.div`
height: 12rem;
    text-align: center;
    color: white;
    border: 1px solid #1B2E94;
    background-color: #1B2E94;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding-top:3rem;
    
`

// const Header = styled.div`
// height:4rem;
// text-align:center;
// color:black;
// `
const LogoText = styled.div`
text-align: center;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 3px;
`
const SubLogoText = styled.div`
text-align: center;
    font-size: 0.8rem;
    letter-spacing: 1rem;
    font-weight:300;
    margin-left: 2%;
`
const Content = styled.div`
// padding: 60px 0;

  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  padding: '0px';
    border: '0.2px solid #e8e7c6';
    border-bottom-left-radius: '0.5rem';
    border-bottom-right-radius: '0.5rem';
  height: 100%;
`

const MainContent = styled.div`

@media (min-width: 1024px) {
padding:3.2rem;
border:0.2px solid #e8e7c6;
}
@media (min-width: 600px) and (max-width: 1023px){
padding:3.2rem;
border:0.2px solid #e8e7c6;
}
@media (min-width: 320px) and (max-width: 480px) {
padding:1.3rem;
border:none;

}
max-height:100%;
`

const MainHeader = styled.div`
text-align: center;
font-weight: bold;
font-size: 2.5rem;
margin-bottom:1rem;
`
// const Button = styled.div`
// backgroudcolor:#2563EA
// `
const css = `
.input{
  border-radius:none;
}
`
const LogoImage = `
.image{
      @media (min-width: 320px) and (max-width: 480px) {
height:2.4rem;
}
}
`
const Logo = styled.div`
// display: block;
    margin-left: auto;
    margin-right: auto;
    width: 16%;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
//     @media (min-width: 320px) and (max-width: 480px) {
//    height: 3rem;
//     width: 3rem;
// }
    background-color: #2563EA;
    box-shadow: 0 2px 2px 0 rgb(1 1 1 / 2%);
`
const Header: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <div style={{maxHeight:'100%'}}>
     {/* <Header /> */}
    <CommonPassContainer>
      
        <Content >
          <LogoContainer>
            <Logo>
              <Image style={{
                display: 'initial',
                marginLeft:'3px',
                marginTop: '2px'

              }} className={LogoImage} src={AppLogo} />
            </Logo>
            <LogoText style={{
              // paddingTop:'4rem'
            }}>WORKSITE</LogoText>
            <SubLogoText>LABS</SubLogoText>
          </LogoContainer>
          {/* <Header>

          </Header> */}
          {/* <MainContent> */}
            {/* <Login /> */}
            {/* <Authorize /> */}
            {/* </MainContent> */}
      </Content>
     
    </CommonPassContainer>
    {/* <Footer /> */}
    </div>
    
    
  );
};

export default Header;
