const config = {
  firestoreCollections: {
    schedules: 'schedules',
    appointments: 'appointments',
    locations: 'locations',
    onetimepassword: "onetimepassword",
    qbenchacknowledgement: "qbenchacknowledgement",
    postalcodes: 'postalcodes',
    reschedulerefunds : "reschedulerefunds"
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  },
  weekDateFormat: 'EEEE, MMM d, yyyy',
  dateTimeFormat: 'LL/dd/yyyy hh:mm a',
  dateFormat: 'LL/dd/yyyy',
  fileDateFormat: 'yyyyLLdd',
  maxSlotsByPeriod: 16,
  schedule: {
    startTime: '6:00a',
    endTime: '5:00p',
    periodDuration: 30,
  },
  Reportschedule: {
    startTime: '6:00a',
    endTime: '5:00p',
    periodDuration: 30,
  },
  products: {
    standard: process.env.REACT_APP_STANDARD_TEST_ID,
    expedited: process.env.REACT_APP_EXPEDITED_TEST_ID,
  },
  currency: 'usd',
  maxAdvanceHours: 72,
  minAdvanceHours: 48,
  vipSlots:{
    maxAdvanceHours: 72,
    minAdvanceHours: 12,
    lastExpressSlotIndex: 12
  },
  lastExpressSlotIndex: 1,
  reportData: {
    location: '450 Bauchet Street, Los Angeles CA 90012',
    reviewedBy: 'Anthony Victorio, MD',
    technician: 'Cherisse Heirs',
  },
  manageScreen: {
    upcoming: 5,
    inProcess: 4,
    completed: 3,
    missed: 2,
  },
};

export { config };
