import { useState, useEffect } from 'react';
import { addMinutes, format, isBefore, parse, differenceInDays } from 'date-fns';
import { includes } from "lodash"

import { Period } from './modules/shared/providers/schedule/models';

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}${alpha !== undefined ? `, ${alpha}` : ''})`;
}

interface WindowDimensions {
  width: number;
  height: number;
}

export function useWindowSize(): WindowDimensions {
  function getSize(): WindowDimensions {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export function getPeriodsFromSchedule(schedule: {
  startTime: string;
  endTime: string;
  periodDuration: number;
}): Period[] {
  const startTime = parse(schedule.startTime, 'h:mmaaaaa', new Date());
  const endTime = parse(schedule.endTime, 'h:mmaaaaa', new Date());

  let time = startTime;
  const periods = [];
  let index = 0;

  while (isBefore(time, endTime)) {
    const next = addMinutes(time, schedule.periodDuration);

    periods.push({
      label: `${format(time, 'h:mm a')} - ${format(next, 'h:mm a')}`,
      startTime: time,
      available: 0,
      index,
    });

    index += 1;
    time = next;
  }

  return periods;
}

export const isMobileAndTablet = () => {
  let check = false;

  (function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera);

  return check;
};

export function isObject(value: any) {
  return typeof value === 'object' && value !== null;
}

export function parseSnapshot(snapshot: any) {
  return snapshot.docs[0]
    ? {
      id: snapshot.docs[0].id,
      data: snapshot.docs[0].data(),
    }
    : null;
}

export const compareStrings = (a: string, b: string) => {
  const leftStr = a.toLowerCase();
  const rightStr = b.toLowerCase();
  return leftStr.includes(rightStr);
};


export function Last7Days() {
  var result = [];
  for (var i = 0; i < 7; i++) {
    var newDate = new Date();
    newDate.setDate(newDate.getDate() - i);
    result.push(`${format(newDate, 'LL/dd/yyyy')}`);
  }
  return result;
}

export const ageCalculator = (date: any) => {

  const dob: any = new Date(date);
  const now: any = new Date();

  const dobYear = dob.getYear();
  const dobMonth = dob.getMonth();
  const dobDate = dob.getDate();

  const currentYear = now.getYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();

  let yearAge: any = '';

  //get years
  yearAge = currentYear - dobYear;

  //get months
  if (currentMonth >= dobMonth)
    //get months when current month is greater
    var monthAge = currentMonth - dobMonth;
  else {
    yearAge--;
    monthAge = 12 + currentMonth - dobMonth;
  }
  //get days
  if (currentDate >= dobDate)
    //get days when the current date is greater
    var dateAge = currentDate - dobDate;
  else {
    monthAge--;
    dateAge = 31 + currentDate - dobDate;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  return {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };
};


export const getSiteHostName = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let HostName = params.get('airline');
  // let HostName: String = window.location.hostname.split('.')[0]
  //   ? window.location.hostname.split('.')[0]
  //   : '';
  if (HostName !== null && HostName?.toUpperCase() !== 'HAL') {
    return false;
  }
  else {
    return true;
  }
};

export const validateHostName = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let HostName = params.get('airline');
  // let HostName: String = window.location.hostname.split('.')[0]
  //   ? window.location.hostname.split('.')[0]
  //   : '';
  if (HostName !== null) {
    return false;
  } else {
    return true;
  }
};


export const getHostName = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let HostName = params.get('airline');
  if (HostName !== null) {
    return HostName;
  } else {
    return 'HAL';
  }
};


export const gettraveltype = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let traveltype = params.get('travel');
  if (traveltype !== null) {
    return traveltype;
  } else {
    return 'DOM';
  }

};

export const getdestination = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let dest = params.get('dest');
  if (dest !== null) {
    return dest;
  } else {
    return 'HI';
  }

};

export const timeDifferenceFilter = (
  parsedDeparture: any, 
  startTime: any, 
  i: any,
  minTime?: number | null,
  maxTime?: any,
) =>{
  const expressPeriodes: any = [12]
  const expressPeriodesOdd: any = [13, 15]

  const departure: any = new Date(parsedDeparture).getHours()
  const departureInMinutes: any = new Date(parsedDeparture).getMinutes()

  const diff: any = differenceInDays(new Date(parsedDeparture), new Date(startTime),)


    let dateDiff: any =
      diff === 3 ?
          departure + (includes(expressPeriodes, departure)
              ? (departureInMinutes == 30 ? 1 : 0)
              : (includes(expressPeriodesOdd, departure)
                  ? (departureInMinutes == 30 ? 2 : 1)
                  : departure == 11 ? (departureInMinutes == 30 ? 0 : 1)
                      : 2))
          : minTime;

  
  return i < dateDiff || (i > maxTime && i <= 15)
} 